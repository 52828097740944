@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body {
    margin: 0;
}

.app {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
}