$dark-grey: #B0B0B0;
$dark-grey-2: #A8A8A8;
$dark-grey-3: #747474;

$black-russian: #202124;
$night-rider: #333333;
$grayscale: #A8A8A8;
$gainsboro: #E7E7E7;

$mercury: #e6e6e6;

$primary-default: #0368E0;

$chili: #D93025;

$shuttle-grey: #65676B;
$shuttle-grey-2: #5f6368;

$white-smoke: #F5F5F5;
$white-additional: #e4e6e9;
$white: #FFF;

$zircon: #DADCE0;
$zircon-inner: #dadce080;

$corn-silk: #FDF6DA;

$max-w-1: 920px;
$max-w-2: 596px;
$max-w-3: 1258px;
$max-w-4: 1140px;
$max-w-5: 1440px;

$mobile-width: 743px;

$event-blue: #0368E0;
$event-yellow: #FCB637;
$event-green: #36C59A;

$message-blue: #2376e8;

$notification-blue: #2793b5;
$notification-red: #e65b65;

$tara-green: #E9F9EE;
$accept-green: #00994D;
$emerald: #52d869;
$denim: #137cbd;
$denim-2: #106ba3;
$solitude: #f5f8fa;
$valhalla: #262338;
$tiger: #FF7500;

$module-shadow: 0px 0px 21px 0px #00000012;

@mixin mobile-separator {
    position: absolute;
    width: 100%;
    content: '';
    border-bottom: 1px solid $zircon;
    left: 0;
    bottom: -30px;
}

@mixin mobile-separator-width {
    width: calc(100% - 40px);
    left: 20px;
}

:export {
    white: $white;
    primaryDefault: $primary-default;
    emerald: $emerald;
    whiteSmoke: $white-smoke;
    darkGrey2: $dark-grey-2;
    darkGrey3: $dark-grey-3;
    moduleShadow: $module-shadow;
    denim: $denim;
    denim2: $denim-2;
    solitude: $solitude;
    grayscale: $grayscale;
    blackRussian: $black-russian;
    shuttleGrey: $shuttle-grey;
    shuttleGrey2: $shuttle-grey-2;

    zircon: $zircon;
    zirconInner: $zircon-inner;
    eventBlue: $event-blue;
    eventGreen: $event-green;
    eventYellow: $event-yellow;
    valhalla: $valhalla;
    maxWidth4: $max-w-4;
    maxWidth1: $max-w-1;
    chili: $chili;
    notificationBlue: $notification-blue;
    notificationRed: $notification-red;
    messageBlue: $message-blue;
    mobileWidth: $mobile-width;
    mercury: $mercury;
    acceptGreen: $accept-green;
    taraGreen: $tara-green;
}