@import '../../../variables.module.scss';

.toast-custom {
    width: max-content;

    button {
        align-self: center;

        svg {
            width: 22px;
            height: 22px;
        }
    }

    &__wrapper {
        border-radius: 6px;
        display: flex;
        padding: 0 24px;
        gap: 15px;
    }

    &__body {
        padding: 0;
        display: flex;
        gap: 15px;
        width: max-content;

        div {
            font-family: Inter;
            line-height: 22px;
            font-size: 14px;
            color: $black-russian;
        }
    }

    .Toastify__toast {
        min-height: 51px;
    }
    .Toastify__close-button--light {
        opacity: 1;
        color: $black-russian;
    }

    .Toastify__close-button {
        height: 36px;
        width: 36px;
        border-radius: 50%;

        svg {
            opacity: 0;
        }
        display: block;
        background: url('../../../assets/svgs/close-x.svg') no-repeat center;

        &:hover {
            background-color: $white-smoke;
        }
    }
}

@media (max-width: $mobile-width) {
    .Toastify {
        display: none;
    }
    // .toast-custom {
    //     left: 50%;
    //     top: 20px;
    // }

    // .Toastify__toast-container--top-center {
    //     transform: translateX(-50%) !important;
    // }
}
